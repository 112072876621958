<template>
    <div>
        <div style="display: flex;">
            <h1>Encerrados</h1> 
            <b-icon v-b-popover.hover.top="'Imóveis que você comprou cotas e que já foram vendidas todas as cotas.'" class="icon-title" icon="question-circle" />
        </div>    
        <ListInvestmentUser id="listInvestmentUserClosed" :closed="true"></ListInvestmentUser>
    </div>
</template>
<script>

import ListInvestmentUser from './ListInvestmentUser.vue'
import { VBPopover, BImg } from 'bootstrap-vue'

export default {
    components: {
        ListInvestmentUser,
        BImg
    },
    directives: {
        'b-popover': VBPopover,
    },
    created() {
        this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
    },
    destroyed() {
        this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
    },

    data(){
        return {
        }
    }
}
</script>
